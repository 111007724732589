<script lang="ts">
export default {
    name: 'ProfileSetting',
}
</script>

<template>
    <div id="container">
        <div id="headline-container">
            <h2>Profile Settings</h2>
        </div>
    </div>
</template>

<style>
#headline-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 82.5%;
    align-items: center;
    padding: 20px;
    animation: colorChange 90s infinite;
    font-size: 20px;
}
</style>